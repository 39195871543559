import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import words from 'lodash/words';

// import * as actions from '../redux/actions/cms';
import amplifyActions from '../redux/actions/amplifyActions';
import * as actionsUser from '../redux/actions/user';
import useActions from './use-actions';

export const useUserLogout = () => {
    const logoutUser = useActions(actionsUser.logoutUser);

    return async () => {
        logoutUser();
    }
}
export const useUserAttributes = () => {
  const attributes = useSelector(state => state.user);
  return attributes;
}

export const getUserAttributes = (triggerFetch) => {
  const _fetchUser = useActions(actionsUser.getUserAttributes);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [attributes, setAttributes] = useState({});

  const fetchUser = async() => {
    try {
      setError(null);
      setLoading(true);
      const response = await _fetchUser();
      setAttributes(response.value);
    }
    catch (e) {
      setError(e);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => { fetchUser() }, [triggerFetch]);
  return attributes;
}

export const useUpdateUserAttributes = () => {
  const { updateEmployee } = useActions(amplifyActions);

  return async values => {

    let user = await Auth.currentAuthenticatedUser({
      bypassCache: true
    });

    const result = await Auth.updateUserAttributes(user, {
        'name': values.name
    });

    await updateEmployee({ input: {
      id: user.username,
      name: values.name
    }})

    return result;
  }
}

export const useUserGroups = () => {
  const [groups, setGroups ] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserGroups = async() => {
    try {
      setError(null);
      setLoading(true);
      let user = await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
      setGroups(user.signInUserSession.accessToken.payload["cognito:groups"]);
    }
    catch (e) {
      setError(e);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => { fetchUserGroups() }, []);
  return groups;
}

export const getInitials = string =>
    words(string)
        .map(word => word.charAt(0))
        .join('')
        .slice(0, 2);

export const useJwtToken = () => {
  const [jwtToken, setJwtToken] = useState(null);
  const fetchData = async () => {
    try {
      const data = await Auth.currentSession()
        .then(res => res.getAccessToken())
        .then(res => res.getJwtToken());
      setJwtToken(data);
    } catch(e) {
      console.log(e)
    }
  };
  useEffect(() => { fetchData() }, []);
  return jwtToken;
}