import { combineReducers } from 'redux';

import crm from './crm';
import user from './user';

export const all = {
  crm,
  user
};

const appReducer = combineReducers(all);

const rootReducer = (state,action) => {
    if(action.type === 'LOGOUT') {
      return appReducer(undefined, action);
    }

    return appReducer(state,action);
};

export default rootReducer;