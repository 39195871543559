import { createAction } from 'redux-actions';
import { Auth } from 'aws-amplify';

export const getUserAttributes = createAction(
  'GET_USER', 
  async () => {
    const response = await Auth.currentAuthenticatedUser({
      bypassCache: true 
    });
    return response.attributes;
  }
);

export const updateUser = createAction(
    'UPDATE_USER', 
    async value => await value
);

export const logoutUser = createAction('LOGOUT');